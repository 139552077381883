/*
Component: <exceed-sortable-items>
Usage: wrap a list of elements with this custom tag.
Notes:
- Use "drag-handle-selector" to set a drag handle
- Use "draggable-selector" to set which items are draggable

Important: At this stage this component works as a custom wrapper for the sortablejs library (see the import). I don't
have loads of faith in that library (it's a right pain actually) but it does at least have backwards compatibility for
IE11 and touch support. So for now we'll stick with it. I've looked at a few other drag and drop libraries (listed
below) and they're all a bit flaky at this stage but they're worth keeping our eyes on. Another option would be to
roll our own using the HTML5 draggable spec - maybe one day.

- https://github.com/bevacqua/dragula (not great module support - uses weird globals - couldn't get it working properly)
- https://github.com/lukasoppermann/html5sortable (relatively well maintained but needs polyfills for IE11 and has no touch)

*/

import { PolymerElement } from '@polymer/polymer';
import Sortable from 'sortablejs';
import axios from '../util/axios';
import PubSub from 'pubsub-js';
import pubSubEvents from '../util/pubsub_event_channels';
import { upKeys, downKeys } from '../util/consts';

// global variable to hold data to revert an item move
let itemRevertData = {};
// global variable to signal keyboard move
let isKeyboardMove = false;

// Default aria messages assume items have names. If they don't, supply values for the appropriate properties.
let ariaGrabbedMessage =
  '"{{ITEM NAME}}" grabbed. Current position: {{POSITION}} of {{TOTAL ITEMS}}. Use up and down keys to move the item, space bar to drop it.';
let ariaDroppedMessage = '"{{ITEM NAME}}" dropped at position {{POSITION}} of {{TOTAL ITEMS}}.';
let ariaMovedMessage = '"{{ITEM NAME}}" moved to position {{POSITION}} of {{TOTAL ITEMS}}';
let ariaMovedToListMessage =
  '"{{ITEM NAME}}" moved to position {{POSITION}} of {{TOTAL ITEMS}} in "{{LIST NAME}}"';
let ariaEscMessage = 'Move of "{{ITEM NAME}}" canceled.';

if (window.Intellum && window.Intellum.i18nStrings) {
  ariaGrabbedMessage = window.Intellum.i18nStrings.item_grabbed;
  ariaDroppedMessage = window.Intellum.i18nStrings.item_dropped;
  ariaMovedMessage = window.Intellum.i18nStrings.item_moved_to_position;
  ariaMovedToListMessage = window.Intellum.i18nStrings.item_moved_to_position_in_list;
  ariaEscMessage = window.Intellum.i18nStrings.item_move_canceled;
}

class ExceedSortableItems extends PolymerElement {
  static get is() {
    return 'exceed-sortable-items';
  }

  static get properties() {
    return {
      groupName: {
        // use this when working with multiple groups (each group will need a unique name)  (sortable.js option)
        type: String,
        value: null,
      },
      dragHandleSelector: {
        // for the drag handle (sortable.js option);
        // pass the selector for the whole item if there's no handle
        type: String,
        value: '.exceed-sortable-items__draghandle',
      },
      draggableSelector: {
        // for the draggable item (sortable.js option)
        type: String,
        value: 'li',
      },
      dragListSelector: {
        // for the list of items that are draggable
        type: String,
        value: 'ul',
      },
      dropUpdateEndpoint: {
        // endpoint to send data to when the item is dropped
        type: String,
        value: '',
      },
      dropUpdateMethod: {
        // method to use when sending the update data
        type: String,
        value: 'put',
      },
      ghostClass: {
        // CSS class for the item that appears under the dragged item before dropping (the drop target)
        type: String,
        value: 'sortableitems__item--ghost',
      },
      draggingClass: {
        // CSS class for the item being dragged
        type: String,
        value: 'sortableitems__item--dragging',
      },
      customDragImageAttribute: {
        // set this to an image url if you wish to use a custom drag image (otherwise the browser will build one)
        type: String,
        value: '',
      },
      customDragImageWidth: {
        // the width to set the custom drag image to (works only when using custom drag images)
        type: Number,
        value: 300,
      },
      keepUntilDropped: {
        // set this to true if you wish to keep a placeholder where you "picked up" the item from
        // this works well with `.sortableitems__droparea` as the `ghostClass`
        type: Boolean,
        value: false,
      },
      keepUntilDroppedPlaceholderClass: {
        // this class will be applied to the placeholder item (if keepUntilDropped is true)
        type: String,
        value: 'sortableitems__placeholder',
      },
      isLockedForDrop: {
        // set this to true if you wish to lock this list as a drop area (useful when managing multiple drag and drop lists)
        type: Boolean,
        value: false,
      },
      emptyListClass: {
        // apply this class to the list when the list of items is empty
        type: String,
        value: '',
      },
      dragErrorMessage: {
        // message if there is an error response from the drop endpoint
        type: String,
        value: 'Error moving item',
      },
      pageScrollElementId: {
        // drag and drop allows for scrolling - by default on the body. Use an id if it's a different element that is scrolling
        type: String,
        value: '',
      },
      pageScrollThreshold: {
        // the threshold for scrolling (an option in Sortable.js)
        type: Number,
        value: 100,
      },
      swapThreshold: {
        // see https://github.com/SortableJS/Sortable#swapthreshold-option
        // if using keepUntilDropped with `sortableitems__droparea` as ghost class, set this to ~0.5
        type: Number,
        value: 1,
      },
      disablePointerAreaSelector: {
        // if dragging on a long page isn't scrolling properly it could be that some (fixed/absolute positioned)
        // elements are inhibiting the events that cause the scroll. Use this selector to disable pointer events
        // on those elements temporarily when scrolling.
        type: String,
        value: '',
      },
      ariaDragMessageId: {
        // use this to point to the id of an aria message for the drag item
        type: String,
        value: 'aria-drag-message',
      },
      a11yListName: {
        // A description/title of the list - used for a11y messages when moving items within and between lists
        type: String,
        value: 'List',
      },
      a11yAnnouncementDivId: {
        // A div (with an aria-live attribute) that will be used to announce movement within and between lists
        // The developer can add one manually to the view and provide it's id via this attribute - otherwise one
        // will be created.
        type: String,
        value: 'sortableItemsA11yAnnouncements',
      },
      ariaGrabbedMessage: {
        // A string that we "read" when an item is selected to move (with {{ITEM NAME}}, {{POSITION}}, and {{TOTAL ITEMS}} being replaced)
        type: String,
        value: ariaGrabbedMessage,
      },
      ariaDroppedMessage: {
        // A string that we "read" when an item is deselected (with {{ITEM NAME}}, {{POSITION}}, and {{TOTAL ITEMS}} being replaced)
        type: String,
        value: ariaDroppedMessage,
      },
      ariaMovedMessage: {
        // A string that we "read" when an item is moved (with {{ITEM NAME}}, {{POSITION}}, and {{TOTAL ITEMS}} being replaced)
        type: String,
        value: ariaMovedMessage,
      },
      ariaMovedToListMessage: {
        // A string that we "read" when an item is moved (with {{ITEM NAME}}, {{POSITION}}, {{TOTAL ITEMS}}, and {{LIST_NAME}} being replaced)
        type: String,
        value: ariaMovedToListMessage,
      },
      ariaEscMessage: {
        // A string that we "read" when an item move is canceled (with {{ITEM NAME}}being replaced)
        type: String,
        value: ariaEscMessage,
      },
      ariaMovedItemIndexAdjustment: {
        // Use this to convert the POSITION that is read out from a 0 index to something that makes sense
        // (making this a property in case there's a need to make additional adjustments (e.g. if there are
        // "locked" items before the first item)
        type: Number,
        value: 1,
      },
      showHoverActive: {
        type: Boolean,
        value: false,
      },
      isNested: {
        // true if this sortable-items is nested within another
        type: Boolean,
        value: false,
      },
      disableSelector: {
        // Selector that, if found on this container or a parent, will disable the component
        type: String,
        value: '.contentdisabled',
      },
      preventDraggableAttribute: {
        // If an item has this attribute it can't be dragged, but other things in the list can be dragged above or below it
        type: String,
        value: 'data-prevent-draggable',
      },
      preventDraggableOnFocusSelector: {
        // If an item has this selector, its ancestor draggable component can't be dragged while the item has focus
        type: String,
        value: '[data-prevent-draggable-on-focus]',
      },
    };
  }

  /**
   * Gets the ids of the items in the list and compiles them as an array
   * (we send this in the request details)
   * */
  getItemIds(listEl) {
    let itemsArray = [];
    listEl.querySelectorAll(this.draggableSelector).forEach((itemEl) => {
      // using forEach and push rather than map because sortablejs is stupid and sometimes includes a ghost list element
      let itemId = itemEl.dataset.itemId;
      if (itemId) {
        itemsArray.push(itemId);
      }
    });
    return itemsArray;
  }

  /**
   * Builds an object about the list an item is in (used for sending data and for keyboard nav)
   * */
  getItemInListData(item, itemList) {
    let itemId = item.dataset.itemId;
    let itemName = item.dataset.a11yItemName;
    let itemIdsArray = this.getItemIds(itemList);
    let itemIndex = itemIdsArray.indexOf(itemId);
    return {
      itemId,
      itemName,
      itemIdsArray,
      itemIndex,
    };
  }

  /**
   * Manipulate the list (toggle css classes) if the list is empty
   * */
  handleEmptyList(listEl) {
    if (this.emptyListClass) {
      if (!listEl.querySelectorAll(this.draggableSelector).length) {
        listEl.classList.add(this.emptyListClass);
        // Ensure that the empty list is really empty, so :empty style selector can be applied
        listEl.innerHTML = '';
      } else {
        listEl.classList.remove(this.emptyListClass);
      }
    }
  }

  /**
   * Set focus after move of item
   * */
  setFocus(movedItem) {
    this.setKeyboardDragData(movedItem);
    movedItem.focus();
    return movedItem;
  }

  /**
   * For moving an item programmatically (i.e. not via the sortable.js and it's drag and drop handlers)
   * Used for:
   * - Keyboard navigation
   * - Reverting on error
   * */
  moveItem(itemEl, newIndex, fromListEl, toListEl) {
    let thisItemEl;

    // Usually, the item being moved is still a child of a list ...
    if (itemEl.parentNode) {
      // clone the items and remove it so that we can assume it has left the list
      // (an item will have left the list if moving between lists but not if dragging within a list - this way we can
      // handle both cases the same way)
      thisItemEl = itemEl.cloneNode(true);
      itemEl.parentNode.removeChild(itemEl);
    } else {
      // ... but if we hit Esc after moving to another list, the item is an orphan
      thisItemEl = itemEl;
    }

    // determine where to put the item
    const itemElToInsertBefore = toListEl.querySelectorAll(this.draggableSelector)[newIndex];

    if (itemElToInsertBefore) {
      toListEl.insertBefore(thisItemEl, itemElToInsertBefore);
    } else {
      // if the item was at the end of the list, there will be no remaining item to insert it before - so use append
      toListEl.appendChild(thisItemEl);
    }

    // the empty list classes need checking (and possibly reverting)
    this.handleEmptyList(toListEl);
    this.handleEmptyList(fromListEl);

    return thisItemEl;
  }

  /**
   * Reverts an item to where it was (we do this on cancel or
     if there is an error response from the server)
   * */
  revertItem(itemEl, originalIndex, originalListEl, intendedDestinationListEl, is_error = false) {
    // we're switching the from and to lists - because we're reverting from an error or on Esc
    let revertedItemEl = this.moveItem(
      itemEl,
      originalIndex,
      intendedDestinationListEl,
      originalListEl,
    );

    let focusItemEl = this.setFocus(revertedItemEl);

    if (is_error) {
      // show an error (NOTE: as with all other uses of flashnotice in custom elements, we probably need to find a better way)
      if (window.Intellum && Intellum.flashnotice) {
        Intellum.flashnotice.show(this.dragErrorMessage, 'warning');
      }
    } else {
      // The revert was intentional, send a11y announcement
      this._announcementDiv.innerText = this.ariaEscMessage.replace(
        '{{ITEM NAME}}',
        itemEl.dataset.a11yItemName,
      );

      // Save the item back at its orginal position and list
      this.sendPositionUpdate(
        itemEl,
        intendedDestinationListEl,
        originalListEl,
        null,
        originalIndex,
      );
    }
    // Clean up and wrap up
    if (itemRevertData.originalComponent != this) {
      // Reset the keyboard drag data for the original list so it knows we've "dropped" the item
      itemRevertData.originalComponent.initKeyboardDragData();
    }
    this.initKeyboardDragData();
    itemRevertData = {};
    focusItemEl.setAttribute('aria-pressed', 'false');
  }

  /**
   * Disable and re-enables the pointer events for any elements found via the `this.disablePointerAreaSelector`
   * attribute. Use this to improve scrolling when elements are blocking the scroll event.
   * */
  handleDisabledPointerAreas(isDisabled) {
    if (this.disablePointerAreaSelector) {
      document.querySelectorAll(this.disablePointerAreaSelector).forEach(function (areaEl) {
        if (isDisabled) {
          areaEl.style.pointerEvents = 'none';
        } else {
          areaEl.style.removeProperty('pointer-events');
        }
      });
    }
  }

  /**
   * Sends the position change to the server (from both drag and drop AND keyboard nav)
   * */
  sendPositionUpdate(item, fromList, toList, fromIndex = null, toIndex = null) {
    if (this.dropUpdateEndpoint) {
      // Unless a toIndex is sent from this.revertItem,
      // calculate the item index ourselevs rather than using `event.newIndex` because sometimes
      // (if createKeepInPlaceClone is true) we will have an extra element in the list
      // (and our getItemIds method will exclude that additional item)
      const itemInListData = this.getItemInListData(item, toList);

      axios[this.dropUpdateMethod](this.dropUpdateEndpoint, {
        item_id: itemInListData.itemId,
        item_index: toIndex || itemInListData.itemIndex,
        item_ids: itemInListData.itemIdsArray,
        section_id: toList.dataset.sectionId,
        from_section_id: fromList.dataset.sectionId,
      })
        .then((response) => {
          let eventDetail = { itemId: item.id };
          if (isKeyboardMove) {
            eventDetail = {
              isKeyboardMove: isKeyboardMove,
              toList: toList,
              itemId: item.id,
            };
          }
          PubSub.publish(pubSubEvents.sortable_success, eventDetail);
        })
        .catch(() => {
          if (fromIndex) {
            this.revertItem(item, fromIndex, fromList, toList, true);
            PubSub.publish(pubSubEvents.sortable_error);
          }
        });
    }
  }

  /**
   * This is the nuts and bolts of the drag and drop functionality. But it basically just instantiates the
   * sortable.js library with a bunch of options and sets up the event response methods
   * */
  makeSortable() {
    let group = {
      name: this.groupName || 'items',
    };
    if (this.isLockedForDrop) {
      group.put = false;
    }

    // derive a value for the scroll attribute of scrollable.js
    // it will be a specific element or true
    let scrollEl, scrollValue;
    if (this.pageScrollElementId) {
      scrollEl = document.getElementById(this.pageScrollElementId);
    }
    scrollValue = scrollEl || true;

    this._sortableInstance = Sortable.create(this._sortableEl, {
      draggable: this.draggableSelector,
      handle: this.dragHandleSelector,
      scroll: scrollValue,
      scrollSensitivity: this.pageScrollThreshold,
      group: group,
      dragClass: this.draggingClass,
      ghostClass: this.ghostClass,
      chosenClass: this.draggingClass,
      swapThreshold: this.swapThreshold,
      forceFallback: true,
      fallbackTolerance: 15,
      onRemove: (event) => {
        this.handleEmptyList(event.from);
      },
      onStart: (event) => {
        this.handleDisabledPointerAreas(true);
        if (this.keepUntilDropped) {
          this.createKeepInPlaceClone(event.item);
        }
        PubSub.publish(pubSubEvents.sortable_start);
        this.handleEmptyList(event.from);
      },
      onAdd: (event) => {
        this.handleEmptyList(event.to);
      },
      onEnd: (event) => {
        this.handleDisabledPointerAreas(false);
        if (this.keepUntilDropped) {
          this.removeKeepInPlaceClone();
        }
        this.sendPositionUpdate(event.item, event.from, event.to, event.oldIndex);
      },
    });
  }

  /**
   * Set up and use the custom drag images (if the customDragImageAttribute value has been set)
   * */
  handleCustomDragImages() {
    // abort if we're not using custom drag images
    if (!this.customDragImageAttribute) {
      return;
    }

    let dragImageContainer, dragImage;

    // pre-load all drag images
    // (just in case - since setDragImage will not work unless image is already loaded and in DOM)
    let imagesArray = [];
    this._sortableEl
      .querySelectorAll(`[${this.customDragImageAttribute}]`)
      .forEach((draggableItem, index) => {
        let customDragImageUrl = draggableItem.getAttribute(this.customDragImageAttribute);
        if (customDragImageUrl) {
          imagesArray[index] = document.createElement('img');
          imagesArray[index].src = customDragImageUrl;
        }
      });

    // handle the drag event
    this._sortableEl.addEventListener('dragstart', (event) => {
      let customDragImageUrl = event.target.getAttribute(this.customDragImageAttribute);

      if (customDragImageUrl) {
        dragImage = document.createElement('img');
        dragImageContainer = document.createElement('div');
        dragImage.src = customDragImageUrl;
        dragImage.width = this.customDragImageWidth;
        dragImage.classList.add('sortableitems__customdragimage');
        dragImageContainer.appendChild(dragImage);
        document.querySelector('body').appendChild(dragImageContainer);
        event.dataTransfer.setDragImage(dragImageContainer, 20, 20);
      }
    });

    // clean up, otherwise they'll get appended to the end of the page
    this._sortableEl.addEventListener('dragend', () => {
      if (dragImage && dragImage.parentNode) {
        dragImage.parentNode.removeChild(dragImage);
      }
      if (dragImageContainer && dragImageContainer.parentNode) {
        dragImageContainer.parentNode.removeChild(dragImageContainer);
      }
    });
  }

  /**
   * Builds a placeholder element if the `keepUntilDropped` attribute is true.
   * (called on dragstart)
   * */
  createKeepInPlaceClone(dragEl) {
    this._placeholderCloneEl = dragEl.cloneNode(true);
    this._placeholderCloneEl.classList.remove(this.ghostClass);
    this._placeholderCloneEl.classList.add(this.keepUntilDroppedPlaceholderClass);
    this._placeholderCloneEl.removeAttribute('item-id');
    this._placeholderCloneEl.dataset.itemId = false;
    dragEl.parentNode.insertBefore(this._placeholderCloneEl, dragEl);
  }

  /**
   * Removes placeholder element if the `keepUntilDropped` attribute is true.
   * (called on dragend)
   * */
  removeKeepInPlaceClone() {
    this._placeholderCloneEl.parentNode.removeChild(this._placeholderCloneEl);
  }

  setRevertData(item, originalIndex, fromList = this._sortableEl, toList = this._sortableEl) {
    itemRevertData = {
      originalComponent: this,
      item: item,
      originalIndex: originalIndex,
      fromList: fromList,
      toList: toList,
    };
  }

  updateRevertData(item, toList) {
    itemRevertData.item = item;
    itemRevertData.toList = toList;
  }

  /**
   * Called when an item is selected for keyboard nav (via space bar on the drag handle)
   * Determines the lists above and below the current list - so that we can use them for keyboard nav.
   * */
  getAdjacentLists(item) {
    const currentListSelector = this.groupName
      ? `exceed-sortable-items[group-name="${this.groupName}"]`
      : 'exceed-sortable-items';
    const matchingListSelector = this.groupName
      ? `exceed-sortable-items[group-name="${this.groupName}"]:not([is-locked-for-drop])`
      : 'exceed-sortable-items:not([is-locked-for-drop])';
    const thisItemList = item.closest(currentListSelector);
    const allLists = document.querySelectorAll(matchingListSelector);
    const adjacentLists = {
      current: thisItemList.querySelector(this.dragListSelector),
    };
    allLists.forEach((list, index) => {
      if (list === thisItemList) {
        if (index > 0) {
          adjacentLists.before = allLists[index - 1].querySelector(this.dragListSelector);
        }
        if (index + 1 < allLists.length) {
          adjacentLists.after = allLists[index + 1].querySelector(this.dragListSelector);
        }
      }
    });
    return adjacentLists;
  }

  /**
   * Works out the data we need for a keyboard move (via the moveItem method)
   * */
  getDataForKeyboardMove(item, direction) {
    const adjacentLists = this.getAdjacentLists(item);
    const itemInListData = this.getItemInListData(item, adjacentLists.current);
    if (
      direction === 'up' &&
      item.previousElementSibling &&
      item.previousElementSibling.matches(this.draggableSelector)
    ) {
      // moving up and there is a previous item in the same list that we can move before
      return {
        item: item,
        fromIndex: itemInListData.itemIndex,
        newIndex: itemInListData.itemIndex - 1,
        fromList: adjacentLists.current,
        toList: adjacentLists.current,
        toListCount: this.getItemIds(adjacentLists.current).length,
      };
    }
    if (direction === 'up' && adjacentLists.before) {
      // moving up and there is a new list before the current one that we can move in to
      return {
        item: item,
        fromIndex: itemInListData.itemIndex,
        newIndex: this.getItemIds(adjacentLists.before).length,
        fromList: adjacentLists.current,
        toList: adjacentLists.before,
        toListCount: this.getItemIds(adjacentLists.before).length,
      };
    }
    if (
      direction === 'down' &&
      item.nextElementSibling &&
      item.nextElementSibling.matches(this.draggableSelector)
    ) {
      // moving down and there is a next item in the same list that we can move below
      return {
        item: item,
        fromIndex: itemInListData.itemIndex,
        newIndex: itemInListData.itemIndex + 1,
        fromList: adjacentLists.current,
        toList: adjacentLists.current,
        toListCount: this.getItemIds(adjacentLists.current).length,
      };
    }
    if (direction === 'down' && adjacentLists.after) {
      // moving down and there is a new list after the current one that we can move in to
      return {
        item: item,
        fromIndex: itemInListData.itemIndex,
        newIndex: 0,
        fromList: adjacentLists.current,
        toList: adjacentLists.after,
        toListCount: this.getItemIds(adjacentLists.after).length,
      };
    }
    return false;
  }

  /**
   * Main handler for the keyboard navigation
   * - determines the data it needs (and whether it can do anything)
   * - if it can move, it
   *   - moves the item
   *   - sends a position update to the server
   *   - flags the item as being in a new list if it has moved lists
   *   - re-calculates the data it needs for the next keyboard action
   *   - maintains the focus on the just-moved item
   * */
  handleKeyboardMove(item, direction) {
    let moveItemData = this.getDataForKeyboardMove(item, direction);
    if (moveItemData) {
      // move the item
      let movedItem = this.moveItem(
        item,
        moveItemData.newIndex,
        moveItemData.fromList,
        moveItemData.toList,
      );
      // send the update to the server
      this.sendPositionUpdate(
        item,
        moveItemData.fromList,
        moveItemData.toList,
        moveItemData.fromIndex,
      );
      // update the info saved for reverting on Esc
      this.updateRevertData(movedItem, moveItemData.toList);

      if (moveItemData.fromList !== moveItemData.toList) {
        // if we're moving to a new list we can re-init the data for this (the current) list
        this.initKeyboardDragData();
        // and then use this flag so that the new list knows to update its data
        movedItem.dataset.isInNewList = true;
      }

      // update our data and focus
      this.setKeyboardDragData(movedItem);
      this.setFocus(movedItem);

      // make an announcement
      if (moveItemData.toList == moveItemData.fromList) {
        this._announcementDiv.innerText = this.ariaMovedMessage
          .replace('{{ITEM NAME}}', moveItemData.item.dataset.a11yItemName)
          .replace('{{POSITION}}', moveItemData.newIndex + this.ariaMovedItemIndexAdjustment)
          .replace('{{TOTAL ITEMS}}', moveItemData.toListCount);
      } else {
        this._announcementDiv.innerText = this.ariaMovedToListMessage
          .replace('{{ITEM NAME}}', moveItemData.item.dataset.a11yItemName)
          .replace('{{POSITION}}', moveItemData.newIndex + this.ariaMovedItemIndexAdjustment)
          .replace('{{TOTAL ITEMS}}', moveItemData.toListCount + 1)
          .replace('{{LIST NAME}}', moveItemData.toList.dataset.a11yListName);
      }
    }
  }

  /**
   * Unfortunately our draggable items don't always have an id. So we can use this if no id exists.
   * (Items will always need a `data-item-id` attribute for sending updates to the server)
   * */
  getItemUniqueIdentifier(item) {
    return `${Array.from(item.classList).join('_')}_${item.dataset.itemId}`;
  }

  /**
   * A convenience method for checking whether an item matches a comparator
   * (currently used for checking our keyboard drag data toggles etc)
   * */
  doesItemMatch(item, comparator) {
    if (!item) {
      return false;
    }
    const itemId = item.id || this.getItemUniqueIdentifier(item);
    return itemId === comparator;
  }

  /**
   * Sets the keyboard drag data for the item being selected or moved
   * - sets the item id
   * */
  setKeyboardDragData(item) {
    this._keyboardDragData = {
      selectedItemId: item.id || this.getItemUniqueIdentifier(item),
    };
  }

  /**
   * Sets (and allows to be reset) an object that we'll use for managing the drag and drop actions.
   * */
  initKeyboardDragData() {
    this._keyboardDragData = {};
  }

  /**
   * Bind to the key events (space to toggle and up/down to move if the item is active)
   * Even if there is a drag handle within the item, for keyboard purposes,
   * all actions are on the item; the handle is ignored.
   *
   * Important: We're using event delegation to manage the keyboard events for the list items. This
   * is because items are cloned (minus any handlers that might be attached to them) when the items
   * are moved.
   * */
  bindDragItemsForList() {
    this._sortableEl.addEventListener('keydown', (event) => {
      if (this.isNested) {
        event.stopPropagation();
      }
      const item = event.target.closest(this.draggableSelector);
      if (!item || item.hasAttribute(this.preventDraggableAttribute)) {
        return false;
      }
      let moveItemData = this.getItemInListData(item, this._sortableEl);
      // when an item is moved to a new list we need to transfer its id to the keyboard data for the new list
      if (item && item.dataset.isInNewList) {
        this.setKeyboardDragData(item);
        delete item.dataset.isInNewList;
      }
      if (event.key === ' ' || event.key === 'Spacebar') {
        if (
          !(
            event.target.isContentEditable ||
            event.target.type == 'text' ||
            event.target.tagName == 'TEXTAREA'
          )
        ) {
          event.preventDefault();
        }
        if (
          this._keyboardDragData.selectedItemId &&
          this.doesItemMatch(item, this._keyboardDragData.selectedItemId)
        ) {
          // We are deselecting (dropping)
          this.initKeyboardDragData();
          item.setAttribute('aria-pressed', 'false');
          this._announcementDiv.innerText = this.ariaDroppedMessage
            .replace('{{ITEM NAME}}', moveItemData.itemName)
            .replace('{{POSITION}}', moveItemData.itemIndex + 1)
            .replace('{{TOTAL ITEMS}}', moveItemData.itemIdsArray.length);
          isKeyboardMove = false;
        } else {
          // We are selecting (grabbing)
          this.setKeyboardDragData(item);
          this.setRevertData(item, moveItemData.itemIndex);
          item.setAttribute('aria-pressed', 'true');
          this._announcementDiv.innerText = this.ariaGrabbedMessage
            .replace('{{ITEM NAME}}', moveItemData.itemName)
            .replace('{{POSITION}}', moveItemData.itemIndex + 1)
            .replace('{{TOTAL ITEMS}}', moveItemData.itemIdsArray.length);
          isKeyboardMove = true;
          PubSub.publish(pubSubEvents.sortable_choose, {
            isKeyboardMove: isKeyboardMove,
            toList: this._sortableEl,
            itemId: item.id,
          });
        }
      } else if (
        upKeys.indexOf(event.key) > -1 &&
        this.doesItemMatch(item, this._keyboardDragData.selectedItemId)
      ) {
        this.handleKeyboardMove(item, 'up');
      } else if (
        downKeys.indexOf(event.key) > -1 &&
        this.doesItemMatch(item, this._keyboardDragData.selectedItemId)
      ) {
        this.handleKeyboardMove(item, 'down');
      } else if (event.key === 'Tab') {
        // use the tab key instead of blur
        this.initKeyboardDragData();
      } else if (event.key === 'Escape' || event.key === 'Esc') {
        if (this._keyboardDragData.selectedItemId) {
          this.revertItem(
            itemRevertData.item,
            itemRevertData.originalIndex,
            itemRevertData.fromList,
            itemRevertData.toList,
          );
        }
      }
    });
  }

  /**
   * Sets up an item to be grabbed and dragged via keyboard.
   * Any drag handle within is ignored for keyboards; you grab the item.
   * - Add a tabindex attribute (to make the item focussable)
   * - Add an aria description (to describe how the item can be acted on)
   * */
  setupA11yDragItems(item) {
    item.setAttribute('grabbable', 'true');
    item.setAttribute('aria-pressed', 'false');
    item.setAttribute('tabindex', 0);

    // Add reference to description
    item.setAttribute('aria-describedby', this.ariaDragMessageId);

    // Hide interior drag handle from screen readers - they'll need to grab the item
    let dragHandleEl = item.querySelector(this.dragHandleSelector);
    if (dragHandleEl) {
      dragHandleEl.setAttribute('aria-hidden', 'true');
    }
  }

  /**
   * Sets up a div that we can use for announcement movements within and between lists...
   * */
  setupAnnouncementDiv() {
    this._announcementDiv = document.getElementById(this.a11yAnnouncementDivId);
    if (!this._announcementDiv) {
      // if there's no announcement div already, create one above the first sortable list
      const firstSortableDivEl = document.querySelector('exceed-sortable-items');
      const newAnnouncementDiv = document.createElement('div');
      newAnnouncementDiv.id = this.a11yAnnouncementDivId;
      newAnnouncementDiv.classList.add('a11y-atonly');
      newAnnouncementDiv.setAttribute('aria-live', 'assertive');
      newAnnouncementDiv.setAttribute('aria-atomic', 'true');
      this._announcementDiv = firstSortableDivEl.parentElement.insertBefore(
        newAnnouncementDiv,
        firstSortableDivEl,
      );
    }
  }

  /**
   * When you hover/select a draggable item, if it's within a draggable item,
     the parent item needs to lose its hover/select appearance so that only
     the child has it
   * */
  handleHoverActive(draggableItem) {
    draggableItem.classList.add('sortableitems__item--showhoveractive');
    let parentDraggableItem = draggableItem.parentElement.closest('.sortableitems__item');
    if (parentDraggableItem) {
      draggableItem.addEventListener('mouseover', (event) => {
        parentDraggableItem.classList.add('sortableitems__item--blockhoveractive');
      });
      draggableItem.addEventListener('mouseout', (event) => {
        parentDraggableItem.classList.remove('sortableitems__item--blockhoveractive');
      });
    }

    /* A button or link within the sortable item should similarly override
       the item's hover/active treatment;
       so should any other interior item designated with `.sortableitems__interactivesubitem` */
    draggableItem
      .querySelectorAll('button, a[href], .sortableitems__interactivesubitem')
      .forEach((interactiveSubitem) => {
        interactiveSubitem.addEventListener('mouseover', (event) => {
          draggableItem.classList.add('sortableitems__item--blockhoveractive');
        });
        interactiveSubitem.addEventListener('mouseout', (event) => {
          draggableItem.classList.remove('sortableitems__item--blockhoveractive');
        });
      });
  }

  /*
    Particularly in Firefox, text can't be selected by mouse in a textarea
    that is within a draggable item. So when that input gets focus,
    we need to temporarily disable the sortable item's draggability.
   */
  disableDraggable(event) {
    event.target.closest(this.draggableSelector).removeAttribute('draggable');
  }

  enableDraggable(event) {
    event.target.closest(this.draggableSelector).setAttribute('draggable', 'true');
  }

  handleDraggableItems() {
    this.querySelectorAll(this.draggableSelector).forEach((draggableItem) => {
      if (!draggableItem.hasAttribute(this.preventDraggableAttribute)) {
        draggableItem.classList.add('sortableitems__item');
        draggableItem.setAttribute('draggable', 'true');
        draggableItem.setAttribute('role', 'option button');
        this.setupA11yDragItems(draggableItem);
        if (this.showHoverActive) {
          this.handleHoverActive(draggableItem);
        }
      }
    });

    this.querySelectorAll(this.preventDraggableOnFocusSelector).forEach((draggableItem) => {
      const boundFocusEventHandler = this.disableDraggable.bind(this);
      const boundBlurEventHandler = this.enableDraggable.bind(this);
      draggableItem.addEventListener('focus', boundFocusEventHandler);
      draggableItem.addEventListener('blur', boundBlurEventHandler);
    });
  }

  initElements() {
    this.setupAnnouncementDiv();
    this._sortableEl = this.querySelector(this.dragListSelector);
    this._sortableEl.classList.add('sortableitems__list');
    this._sortableEl.dataset.a11yListName = this.a11yListName;
    if (this.emptyListClass) {
      // Check initially if list is empty
      this.handleEmptyList(this._sortableEl);
    }
    this.handleDraggableItems();
    this.bindDragItemsForList();
    this.handleCustomDragImages();
  }

  ready() {
    super.ready();

    if (this.closest(this.disableSelector)) {
      // Change tabindex of sortable items
      this.querySelectorAll(this.draggableSelector).forEach((draggableItem) => {
        draggableItem.setAttribute('tabindex', -1);
      });
      return;
    }

    this.initKeyboardDragData();
    this.initElements();
    this.makeSortable();
  }
}

customElements.define('exceed-sortable-items', ExceedSortableItems);
